import 'bootstrap/dist/css/bootstrap.min.css';
import {Card} from "react-bootstrap";
import './style.css';

const ProjectCard = ({image, title, time, url, descriptions}) => {
    return (
        <Card className="my-3 project-card">
            <div className="top-section">
                <div className="image-container">
                    <img src={image} alt={title} className="project-image" />
                </div>
                <div className="text-container">
                    <div className="title-time">
                        <Card.Title className="text-center display-6">{title}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted text-center">{time}</Card.Subtitle>
                    </div>
                    <Card.Body className="bottom-section">
                        <Card.Text className="description-text">
                            <ul className="text-left">
                                {descriptions.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                            <div className="url">
                                <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                            </div>
                        </Card.Text>
                    </Card.Body>
                </div>
            </div>
        </Card>
    )
}

export default ProjectCard;