import React, {useState} from "react";
import {Button, Container, Form, FormControl, Nav, Navbar} from "react-bootstrap";

import './style.css'

const Header = () => {
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        if (searchQuery.trim()) {
            window.open(`https://www.bing.com/search?q=${encodeURIComponent(searchQuery)}`, '_blank');
        }
    };

    return (<Navbar bg="dark" variant="dark" expand="md" sticky="top">
        <Container fluid>
            <Navbar.Brand href="/">Home</Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll"/>
            <Navbar.Collapse id="navbarScroll">
                <Nav className="me-auto my-2 my-lg-0">
                    <Nav.Link href="about">About Me</Nav.Link>
                    {/*<Nav.Link href="portfolio">Portfolio</Nav.Link>*/}
                    <Nav.Link href="projects">Projects</Nav.Link>
                    {/*<Nav.Link href="faq">FAQ</Nav.Link>*/}
                    <Nav.Link href="contact">Contact</Nav.Link>
                </Nav>

                <Form className="d-flex" onSubmit={handleSearch}>
                    <FormControl
                        type="search"
                        placeholder="Search in Bing"
                        className="me-2"
                        aria-label="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <Button variant="outline-light" type="submit">Search</Button>
                </Form>
            </Navbar.Collapse>
        </Container>
    </Navbar>);
}

export default Header;