import React from "react";
import {Container} from "react-bootstrap";

export default function AboutMe() {
    return (
        <Container>
            <h1 className="display-1">About Me</h1>
        </Container>
    );
}
