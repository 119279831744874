import React, {useState} from "react";
import {Alert, Button, Card, Col, Container, Form, Row} from 'react-bootstrap';
import {Link} from "react-router-dom";

export default function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        // Send the message to the server
        console.log({name, email, message});

        setIsSubmitting(false);
        setSubmitted(true);
    };

    return (
        <Container>
            <h1 className="display-1">Contact</h1>
            <h6>If you have any questions or inquiries, feel free to reach out to me through the form below or via my email or phone number.</h6>

            <Row className="g-4 mb-5 mt-3">
                <h6 className="display-6">Contact Information</h6>
                <Col md={4}>
                    <Card className="h-100">
                        <Card.Body>
                            <Card.Title>Email</Card.Title>
                            <Card.Text>
                                <Link to="mailto:sinan.li@mail.utoronto.ca">sinan.li@mail.utoronto.ca</Link>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card className="h-100">
                        <Card.Body>
                            <Card.Title>Phone</Card.Title>
                            <Card.Text>
                                <Link to="tel:+12893808035">+1 (289) 380-8035</Link>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card className="h-100">
                        <Card.Body>
                            <Card.Title>Address</Card.Title>
                            <Card.Text>
                                <Link to="https://maps.app.goo.gl/1aXq7MRdtJBSwfR5A" target="_blank" rel="noreferrer">297 College St, Toronto, ON M5T 1S2</Link>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                {/*<iframe*/}
                {/*    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.550030529271!2d-79.40412845842548!3d43.65752935226992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b34c1c81e70f9%3A0x21cbad928eea7c86!2s297%20College%20St%2C%20Toronto%2C%20ON%20M5T%200C2!5e0!3m2!1sen!2sca!4v1709486585431!5m2!1sen!2sca"*/}
                {/*    width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade">*/}
                {/*</iframe>*/}
            </Row>

            {submitted && (
                <Alert variant="success">
                    Your message has been sent successfully!
                </Alert>
            )}

            <Form onSubmit={handleSubmit} className="border p-3 rounded">
                <h3 className="display-6">Send me a message</h3>
                <Form.Group className="mb-3">
                    <Form.Label><strong>Name</strong></Form.Label>
                    <Form.Control type="text" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} required/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label><strong>Email</strong></Form.Label>
                    <Form.Control type="email" placeholder="Your Email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label><strong>Message</strong></Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Your Message" value={message} onChange={(e) => setMessage(e.target.value)} required/>
                </Form.Group>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Sending...' : 'Send Message'}
                </Button>
            </Form>
        </Container>
    );
}
