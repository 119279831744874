import React, {useEffect, useState} from "react";
import {Container, Nav} from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css';

import './style.css';

export default function Home() {
    const hello = ['Hello', 'Bonjour', 'Hola', 'Ciao', 'こんにちは', '你好', "안녕하세요", "สวัสดี", "Hallo", "Olá", "Здравствуйте", "مرحبا"];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [fade, setFade] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setFade(true);
            let randomIndex = Math.floor(Math.random() * hello.length);
            while (randomIndex === currentIndex) randomIndex = Math.floor(Math.random() * hello.length);
            setTimeout(() => {
                setCurrentIndex(randomIndex);
                setFade(false);
            }, 250);
        }, 5000);

        return () => clearInterval(interval);
    }, [hello.length, currentIndex]);

    return (
        <Container>
            <h1 className={`display-1 ${fade ? 'fade-out' : 'fade-in'}`}>
                {hello[currentIndex]}
            </h1>
            <h5 className="mt-3">
                My name is <i>Sinan Li</i>, and you can call me <i>Lance</i>. Welcome to my website!
            </h5>

            {/* Table of Contents */}
            <Nav className="vertical-nav mt-4">
                <Nav.Item>
                    <Nav.Link href="about" className="fs-1 display-6">About Me</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="projects" className="fs-1 display-6">Projects</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="contact" className="fs-1 display-6">Contact</Nav.Link>
                </Nav.Item>
            </Nav>
        </Container>
    );
}