import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import './App.css';

import Header from "./components/Header";

import AboutMe from "./pages/AboutMe";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Footer from "./components/Footer";

function App() {
    return (
        <div className={'App'}>
            <BrowserRouter>
                <Header/>

                <div className="main-container">
                <Routes>
                    <Route path={'/'} element={<Home/>}/>
                    <Route path={'about'} element={<AboutMe/>}/>
                    <Route path={'contact'} element={<Contact/>}/>
                    <Route path={'projects'} element={<Projects/>}/>
                </Routes>
                </div>

                <Footer/>
            </BrowserRouter>
        </div>
    );
}

export default App;
