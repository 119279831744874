import React from "react";
import {Link} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const Footer = () => {
    return (
        <footer className="d-flex flex-wrap justify-content-between align-items-center p-3 fixed-bottom bg-dark">

            <div className="d-flex align-items-center">
                <span className="text-light">© 2024 <Link to={"/"} className="text-light">Sinan Li</Link></span>
            </div>

            <ul className="nav justify-content-end list-unstyled d-flex">
                {/*<li className="ms-3"><a className="text-light" target="_blank" href="https://twitter.com/lance14161/" rel="noreferrer"><i className="bi bi-x"></i></a></li>*/}
                <li className="ms-3"><a className="text-light" target="_blank" href="https://instagram.com/lanceli1416/" rel="noreferrer"><i className="bi bi-instagram"></i></a></li>
                {/*<li className="ms-3"><a className="text-light" target="_blank" href="/"><i className="bi bi-facebook"></i></a></li>*/}
                <li className="ms-3"><a className="text-light" target="_blank" href="https://www.linkedin.com/in/lanceli-1416/" rel="noreferrer"><i className="bi bi-linkedin"></i></a></li>
                <li className="ms-3"><a className="text-light" target="_blank" href="https://www.github.com/lanceli1416/" rel="noreferrer"><i className="bi bi-github" rel="noreferrer"></i></a></li>
                <li className="ms-3"><a className="text-light" target="_blank" href="https://git.lance1416.com/lance1416/" rel="noreferrer"><i className="bi bi-git" rel="noreferrer"></i></a></li>
            </ul>
        </footer>
    );
}

export default Footer;