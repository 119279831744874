import React from "react";
import ProjectCard from "../../components/ProjectCard";

import img_evogami from '../../assets/images/evogami.png';
import img_petpal from '../../assets/images/petpal.svg';
import img_linguishtic from '../../assets/images/Lingu-ish-tic.png';
import {Container} from "react-bootstrap";

export default function Projects() {
    const projects = [
        {
            image: img_evogami,
            title: 'EVOGAMI',
            time: 'Indie Game - 2024',
            url: 'https://tenfold-games.itch.io/evogami',
            descriptions: [
                <><b>Co-led</b> a team of 12 in this <b>cross-disciplinary</b> project, with CS and music students from UofT, and game designers from Sheridan College.</>,
                <><b>Programming lead</b>, implemented core mechanics including managers, movement, puzzle mechanisms, UI/UX, and game state management.</>,
                <>Enforced <b>code quality</b> by following design patterns, writing clean code, and conducting code reviews.</>,
                <>Hosted a team management platform, provided technical support, organized team meetings, and supervised overall progression.</>
            ]
        },
        {
            image: img_petpal,
            title: 'PetPal',
            time: 'Full Stack Website - 2023',
            url: 'https://petpal.lance1416.com',
            descriptions: [
                <>Collaborated in a team of 4 to design and launch a <b>full-stack</b> pet adoption website.</>,
                <>Engineered a <b>RESTful backend</b> using Python Django, ensuring robust data handling and server-side logic.</>,
                <>Crafted a <b>responsive front-end</b> interface using React, ensuring a seamless user experience across devices.</>,
            ]
        },
        {
            image: img_linguishtic,
            title: 'Lingu-ish-tic',
            time: 'HCI Field Study and Design - 2022',
            url: 'https://www.figma.com/proto/kl3nGpUz6ruBt1ElFb3HJG/Art-Ish-Tic?node-id=0-1&t=7tX7UM6buDWLJeKy-1',
            descriptions: [
                <><b>Co-led</b> a team of 6 in a <b>human-computer interaction</b> design project, focusing on language learning.</>,
                <>Followed the <b>double diamond</b> design process, conducted field studies, and user interviews.</>,
                <>Actively engaged in <b>heuristic studies</b> and <b>expert evaluations</b>, providing technical support for the team.</>,
                <>Proposed future potentials in <b>mixed reality and AI</b> for language learning in a social-interaction based <b>immersive</b> environment.</>
            ]
        }
    ];

    return (
        <Container>
            <h1 className="display-1">Projects</h1>
            <h6>Here are some of the projects I have worked on.</h6>

            {projects.map((project, index) => (
                <ProjectCard
                    key={index}
                    image={project.image}
                    title={project.title}
                    time={project.time}
                    url={project.url}
                    descriptions={project.descriptions}
                />
            ))}
        </Container>
    );
}
